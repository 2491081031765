import { useState, createContext, useEffect, useMemo } from "react";
import { IntlProvider } from "react-intl";

import globalEn from "@src/utility/intl/en.json";
import globalEs from "@src/utility/intl/es.json";
import globalBr from "@src/utility/intl/pt-BR.json";

import homeEn from "@src/app/home/intl/en.json";
import homeEs from "@src/app/home/intl/es.json";
import homeBr from "@src/app/home/intl/pt-BR.json";

import qualifyEn from "@src/app/qualify/intl/en.json";
import qualifyEs from "@src/app/qualify/intl/es.json";
import qualifyBr from "@src/app/qualify/intl/pt-BR.json";

import workplanEn from "@src/app/workplan/intl/en.json";
import workplanEs from "@src/app/workplan/intl/es.json";
import workplanBr from "@src/app/workplan/intl/pt-BR.json";

import loginEn from "@src/app/login/intl/en.json";
import loginEs from "@src/app/login/intl/es.json";
import loginBr from "@src/app/login/intl/pt-BR.json";

import calendarizationEn from "@src/app/calendarization/intl/en.json";
import calendarizationEs from "@src/app/calendarization/intl/es.json";
import calendarizationBr from "@src/app/calendarization/intl/pt-BR.json";

import assetTypeEn from "@src/app/assets/intl/en.json";
import assetTypeEs from "@src/app/assets/intl/es.json";
import assetTypeBr from "@src/app/assets/intl/pt-BR.json";

import { flattenMessages } from "./flattenMessages";

const menuMessages = {
  en: {
    ...globalEn,
    ...homeEn,
    ...qualifyEn,
    ...workplanEn,
    ...loginEn,
    ...calendarizationEn,
    ...assetTypeEn,
  },
  es: {
    ...globalEs,
    ...homeEs,
    ...qualifyEs,
    ...workplanEs,
    ...loginEs,
    ...calendarizationEs,
    ...assetTypeEs,
  },
  pt: {
    ...globalBr,
    ...homeBr,
    ...qualifyBr,
    ...workplanBr,
    ...loginBr,
    ...calendarizationBr,
    ...assetTypeBr,
  },
};

const IntlContext = createContext();

const getInitialLocale = () => {
  const storedLocale = localStorage.getItem("LOCALE_STORAGE_KEY");
  const userLanguage = (navigator.language || navigator.userLanguage).split(
    "-"
  )[0];
  return storedLocale || userLanguage || "en";
};

const IntlProviderWrapper = ({ children }) => {
  const [locale, setLocale] = useState(getInitialLocale);

  const messages = useMemo(
    () => flattenMessages(menuMessages[locale] || menuMessages["en"]),
    [locale]
  );

  useEffect(() => {
    localStorage.setItem("LOCALE_STORAGE_KEY", locale);
  }, [locale]);

  const switchLanguage = (lang) => {
    const language = lang.split("-")[0];
    setLocale(language);
  };

  return (
    <IntlContext.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export { IntlProviderWrapper, IntlContext };
